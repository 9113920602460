import React, { forwardRef } from "react";
import { Link as GatsbyLink } from "gatsby";
import type { GatsbyLinkProps } from "gatsby";

export type LinkProps = Omit<GatsbyLinkProps<unknown>, "ref"> & {
  underline?: boolean | "group";
};

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    { children, to, underline, className: classNameProp, ...any },
    ref
  ): JSX.Element => {
    const internal = /^\/(?!\/)/.test(to);
    const classNames: string[] = [];

    if (classNameProp) classNames.push(classNameProp);

    if (underline) {
      classNames.push("hover-underline");
    }

    const className = classNames.join(" ");

    if (internal) {
      return (
        <GatsbyLink to={to} className={className} innerRef={ref} {...any}>
          {children}
        </GatsbyLink>
      );
    }

    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        ref={ref}
        {...any}
      >
        {children}
      </a>
    );
  }
);

export default Link;
