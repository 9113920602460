import React from "react";
import Link from "@/components/link";
import type { LinkProps } from "@/components/link";

const HeaderLink: React.FC<LinkProps> = ({
  children,
  className,
  onClick,
  ...props
}) => (
  <Link
    className={`inline-block py-4 px-gutter ${className ?? ""}`}
    onClick={onClick ?? ((e) => e.stopPropagation())}
    {...props}
  >
    {children}
  </Link>
);

export default HeaderLink;
