import React, { createContext, useState, useMemo } from "react";

export interface VideoContextProps {
  mutedByDefault: boolean;
  setMutedByDefault: (muted: boolean) => void;
  videoInViewport: boolean;
  setVideoInViewport: (inViewport: boolean) => void;
}

const videoContextDefaults = {
  mutedByDefault: true,
  videoInViewport: false,
};

const VideoContext = createContext<VideoContextProps>(
  videoContextDefaults as VideoContextProps
);

const VideoProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [mutedByDefault, setMutedByDefault] = useState(
    videoContextDefaults.mutedByDefault
  );

  const [videoInViewport, setVideoInViewport] = useState(
    videoContextDefaults.videoInViewport
  );

  const value = useMemo(
    () => ({
      mutedByDefault,
      setMutedByDefault: (muted: boolean) => setMutedByDefault(muted),
      videoInViewport,
      setVideoInViewport: (inViewport: boolean) =>
        setVideoInViewport(inViewport),
    }),
    [mutedByDefault, setMutedByDefault, videoInViewport, setVideoInViewport]
  );

  return (
    <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
  );
};

export default VideoContext;

export { VideoProvider };
