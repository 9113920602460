import React from "react";
import { MotionProps, m as motion } from "framer-motion";
import ThemeContext from "@/components/theme-context";

const HeaderGradient: React.FC<
  React.ComponentPropsWithoutRef<"div"> & MotionProps
> = ({ className, ...props }) => (
  <ThemeContext.Consumer>
    {({ headerHeight, headerGradient }) => (
      <motion.div
        className={`gradient-mask fixed top-0 inset-x-0 z-10 ${
          className ?? ""
        }`}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        initial="hidden"
        animate={headerGradient ? "visible" : "hidden"}
        transition={{ duration: 0.125, ease: "easeInOut" }}
        {...props}
      >
        <div
          className="transition duration-75 ease-in-out bg-back"
          style={{ height: `${headerHeight}px` }}
        />
      </motion.div>
    )}
  </ThemeContext.Consumer>
);

export default HeaderGradient;
