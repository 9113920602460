import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { m as motion } from "framer-motion";
import ThemeContext from "@/components/theme-context";
import HeaderLink from "@/layouts/partials/header-link";
import HeaderNavItem from "./header-nav-item";
import { useIsMedium } from "@/hooks/useMediaQuery";

type HeaderProps = {
  homeLink?: string;
};

const Header: React.FC<HeaderProps> = ({ homeLink }) => {
  const isMedium = useIsMedium();
  const { setHeaderHeight, navigationHidden } = useContext(ThemeContext);
  const headerRef = useRef<HTMLElement>(null);

  const calculateHeaderDimensions = useCallback(() => {
    if (headerRef?.current) setHeaderHeight(headerRef.current.clientHeight);
  }, [headerRef, setHeaderHeight]);

  useEffect(() => {
    window.addEventListener("resize", calculateHeaderDimensions);

    calculateHeaderDimensions();

    return () => {
      window.removeEventListener("resize", calculateHeaderDimensions);
    };
  }, [calculateHeaderDimensions]);

  const logoHidden = useMemo<boolean>(() => {
    if (navigationHidden === null) return !isMedium;
    return !navigationHidden;
  }, [isMedium, navigationHidden]);

  const navItemsHidden = useMemo<boolean>(() => {
    if (navigationHidden === null) return false;
    return navigationHidden;
  }, [navigationHidden]);

  return (
    <header className="group">
      <div className="relative z-40 text-front">
        <nav
          className="fixed inset-x-0 top-0 text-xl tracking-tight uppercase xs:text-2xl xl:text-3xl"
          ref={headerRef}
        >
          <ul className="min-h-[4rem] flex items-center justify-between">
            <motion.li
              variants={{
                show: {
                  display: "block",
                  opacity: 1,
                  y: 0,
                  transitionEnd: {
                    display: "block",
                  },
                },
                hide: {
                  y: -10,
                  opacity: 0,
                  transitionEnd: {
                    display: "none",
                  },
                },
              }}
              initial="show"
              transition={{ ease: "easeOut" }}
              animate={!logoHidden ? "show" : "hide"}
              className="absolute inset-0 flex items-center justify-center text-center md:inset-x-1/4"
            >
              <HeaderLink to={!isMedium ? homeLink ?? "/work" : "/"}>
                <span>Julia Sellmann</span>
                <span className="sr-only">| Home</span>
              </HeaderLink>
            </motion.li>
            <HeaderNavItem to="/work" hidden={navItemsHidden}>
              Work
            </HeaderNavItem>
            <HeaderNavItem to="/info" hidden={navItemsHidden}>
              Info
            </HeaderNavItem>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
