import { hslToString } from "@/helpers/colors";
import { graphql, useStaticQuery } from "gatsby";
import React, { useState, useMemo, useCallback } from "react";

export interface ThemeContextProps {
  headerHeight: number;
  setHeaderHeight: (height: number) => void;
  navigationHidden: boolean | null;
  hideNavigation: (hide: boolean | null) => void;
  backgroundColor: string | null;
  foregroundColor: string | null;
  setTheme: (
    backgroundColor: string | null,
    foregroundColor: string | null
  ) => void;
  resetTheme: () => void;
  headerGradient: boolean;
  showHeaderGradient: (show: boolean) => void;
}

const themeContextDefaults = {
  headerHeight: 0,
  navigationHidden: null,
  backgroundColor: null,
  foregroundColor: null,
  headerGradient: false,
};

const ThemeContext = React.createContext<ThemeContextProps>(
  themeContextDefaults as ThemeContextProps
);

const ThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const { settings } = useStaticQuery<Queries.ThemeSettingsQuery>(graphql`
    query ThemeSettings {
      settings: sanitySettings {
        theme {
          backgroundColor {
            hsl {
              h
              s
              l
            }
          }
          foregroundColor {
            hsl {
              h
              s
              l
              a
            }
          }
        }
      }
    }
  `);

  const [headerGradient, showHeaderGradient] = useState<boolean>(
    themeContextDefaults.headerGradient
  );

  const [backgroundColor, setBackgroundColorState] = useState<string | null>(
    themeContextDefaults.backgroundColor ??
      hslToString(settings?.theme?.backgroundColor?.hsl ?? null)
  );

  const [foregroundColor, setForegroundColorState] = useState<string | null>(
    themeContextDefaults.foregroundColor ??
      hslToString(settings?.theme?.foregroundColor?.hsl ?? null)
  );

  const setBackgroundColor = useCallback(
    (color: string | null) => {
      if (!color) {
        setBackgroundColorState(
          hslToString(settings?.theme?.backgroundColor?.hsl ?? null)
        );
        return;
      }

      setBackgroundColorState(color);
    },
    [setForegroundColorState, settings]
  );

  const setForegroundColor = useCallback(
    (color: string | null) => {
      if (!color) {
        setForegroundColorState(
          hslToString(settings?.theme?.foregroundColor?.hsl ?? null)
        );
        return;
      }

      setForegroundColorState(color);
    },
    [setForegroundColorState, settings]
  );

  const [headerHeight, setHeaderHeightState] = useState(
    themeContextDefaults.headerHeight
  );

  const [navigationHidden, hideNavigation] = useState<boolean | null>(
    themeContextDefaults.navigationHidden
  );

  const value = useMemo(
    () => ({
      headerHeight,
      setHeaderHeight: (height: number) => setHeaderHeightState(height),
      navigationHidden,
      hideNavigation: (hide: boolean | null = true) => hideNavigation(hide),
      backgroundColor,
      foregroundColor,
      setTheme: (
        backgroundColor: string | null,
        foregroundColor: string | null
      ) => {
        setBackgroundColor(backgroundColor);
        setForegroundColor(foregroundColor);
      },
      resetTheme: () => {
        setBackgroundColor(null);
        setForegroundColor(null);
      },
      headerGradient,
      showHeaderGradient,
    }),
    [
      headerHeight,
      setHeaderHeightState,
      navigationHidden,
      hideNavigation,
      backgroundColor,
      setBackgroundColor,
      foregroundColor,
      setForegroundColor,
      headerGradient,
      showHeaderGradient,
    ]
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeContext;

export { ThemeProvider };
