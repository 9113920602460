import React, { useEffect, useRef } from "react";
import { type PageProps } from "gatsby";
import { LazyMotion, AnimatePresence, domAnimation } from "framer-motion";
import "@resources/styles/app.css";
import Header from "@/layouts/partials/header";
import HeaderGradient from "@/layouts/partials/header-gradient";
import ThemeContext, { ThemeProvider } from "@components/theme-context";
import { VideoProvider } from "@components/video-context";

interface LayoutProps {
  readonly children: React.ReactNode;
}

const Layout: React.FC<LayoutProps & PageProps> = ({ location, children }) => {
  const scrollContainer = useRef<HTMLDivElement>(null);

  const calculateViewportSize = () => {
    document.documentElement.style.setProperty(
      "--scrollbar-width",
      `${Math.max(
        window.innerWidth - document.documentElement.clientWidth,
        0
      )}px`
    );
  };

  useEffect(() => {
    const opts: AddEventListenerOptions & EventListenerOptions = {
      passive: true,
    };

    calculateViewportSize();

    window.addEventListener("resize", calculateViewportSize, opts);

    return () =>
      window.removeEventListener("resize", calculateViewportSize, opts);
  }, []);

  useEffect(
    () => scrollContainer?.current?.scrollTo({ top: 0, behavior: "smooth" }),
    [children]
  );

  return (
    <ThemeProvider>
      <VideoProvider>
        <ThemeContext.Consumer>
          {({ backgroundColor, foregroundColor }) => (
            <LazyMotion features={domAnimation}>
              {(backgroundColor || foregroundColor) && (
                <style>
                  {`
                :root {
                  ${
                    backgroundColor
                      ? `--color-background: ${backgroundColor};`
                      : ""
                  }
                  ${
                    foregroundColor
                      ? `--color-foreground: ${foregroundColor};`
                      : ""
                  }
                }
              `}
                </style>
              )}
              <div
                className="overflow-y-auto h-screen-s scroll-smooth"
                ref={scrollContainer}
              >
                {location.pathname === "/" && (
                  <h1 className="sr-only">Julia Sellmann Photography</h1>
                )}
                <div className="min-h-screen-s">
                  <Header
                    homeLink={location.pathname === "/work/" ? "/" : undefined}
                  />
                  <HeaderGradient />
                  <AnimatePresence mode="wait" initial={false}>
                    {children}
                  </AnimatePresence>
                </div>
              </div>
            </LazyMotion>
          )}
        </ThemeContext.Consumer>
      </VideoProvider>
    </ThemeProvider>
  );
};

export default Layout;
