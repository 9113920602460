import React from "react";
import { m as motion } from "framer-motion";
import HeaderLink from "@/layouts/partials/header-link";

interface HeaderNavItemProps {
  to?: string;
  hidden?: boolean;
  children: React.ReactNode;
}

const HeaderNavItem: React.FC<HeaderNavItemProps> = ({
  to,
  hidden,
  children,
}) => (
  <motion.li
    variants={{
      show: {
        display: "block",
        opacity: 1,
        y: 0,
        transitionEnd: {
          display: "block",
        },
      },
      hide: {
        opacity: 0,
        y: 20,
        transitionEnd: {
          display: "none",
        },
      },
    }}
    initial="hide"
    transition={{ ease: "easeInOut" }}
    animate={hidden ? "hide" : "show"}
  >
    {to ? (
      <HeaderLink
        to={to}
        className="group/link"
        activeClassName="[&>span>span]:w-full"
      >
        <span className="relative py-0.5">
          {children}
          <span className="absolute bottom-0 left-0 w-0 transition-all duration-75 ease-in-out border-b border-current group-hover/link:w-full sm:border-b-[1.5px] lg:border-b-2" />
        </span>
      </HeaderLink>
    ) : (
      children
    )}
  </motion.li>
);

HeaderNavItem.defaultProps = {
  hidden: false,
};

export default HeaderNavItem;
