import { useEffect, useState } from "react";

export const useMediaQuery = function (query: string) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);

    const opts: AddEventListenerOptions & EventListenerOptions = {
      passive: true,
    };

    setMatches(media.matches);

    const listener = () => setMatches(media.matches);

    if (typeof media.addEventListener === "function") {
      media.addEventListener("change", listener, opts);
    } else {
      media.addListener(listener);
    }

    return () => {
      if (typeof media.removeEventListener === "function") {
        media.removeEventListener("change", listener, opts);
      } else {
        media.removeListener(listener);
      }
    };
  }, [matches, query]);

  return matches;
};

export const useIsSmall = () => useMediaQuery("(min-width: 480px)");
export const useIsMedium = () => useMediaQuery("(min-width: 768px)");
export const useIsLarge = () => useMediaQuery("(min-width: 1024px)");
